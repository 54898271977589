import "assets/styles/production.scss";
import pic1 from "assets/images/services/pic1.jpg";
import pic2 from "assets/images/services/pic2.jpg";
import pic3 from "assets/images/services/pic3.jpg";
import pic4 from "assets/images/services/pic4.jpg";
import pic5 from "assets/images/services/pic5.jpg";
import pic6 from "assets/images/services/pic6.jpg";
import pic7 from "assets/images/services/pic7.jpg";
import pic8 from "assets/images/services/pic8.jpg";
import pic9 from "assets/images/services/pic9.jpg";
import { Link } from "react-router-dom";
import Layout from "layouts/Layout";

export default function ProductionPage() {
  return (
    <Layout>
      <main className="page">
        <div className="page__banner-block">
          <div className="banner-block__image3">
            <h1 className="banner-header">
              Гідрообразне
              <br />
              різання
              <br />
              граніту
            </h1>
            <p className="banner-text3">
              Гідрорізка забезпечує ідеально рівну та строго вертикальну кромку
              без сколів. Такі вироби не потребують додаткової обробки.
            </p>
          </div>
        </div>
        <section className="page__product-blocks">
          <div className="gallery__container container">
            <div className="gallery__body">
              <div className="gallery__column">
                <Link to="/production/facingTiles">
                  <div className="gallery__block">
                    <div className="block__description">
                      <h1 className="block__header">
                        Плитка
                        <br />
                        гранітна облицювальна
                      </h1>
                    </div>
                    <img className="block__image" src={pic1} alt="" />
                  </div>
                </Link>

                <Link to="/production/sidewalkTiles">
                  <div className="gallery__block">
                    <div className="block__description">
                      <h2 className="block__header">
                        Плитка
                        <br />
                        гранітна для тротуарів
                      </h2>
                    </div>
                    <img className="block__image" src={pic2} alt="" />
                  </div>
                </Link>

                <Link to="/production/monuments">
                  <div className="gallery__block">
                    <div className="block__description">
                      <h2 className="block__header">
                        Пам'ятники
                        <br />
                        та надгробки
                      </h2>
                    </div>
                    <img className="block__image" src={pic3} alt="" />
                  </div>
                </Link>
                <Link to="/production/furniture">
                  <div className="gallery__block">
                    <div className="block__description">
                      <h2 className="block__header">
                        Вироби з граніту
                        <br />
                        Екстер'єр та меблі
                      </h2>
                    </div>
                    <img className="block__image" src={pic4} alt="" />
                  </div>
                </Link>
                <Link to="/production/pavingStones">
                  <div className="gallery__block">
                    <div className="block__description">
                      <h2 className="block__header">
                        Бруківка пиляна колота <br />
                        для тротуарів
                      </h2>
                    </div>
                    <img className="block__image" src={pic5} alt="" />
                  </div>
                </Link>
                <Link to="/production/designedRubbleStone">
                  <div className="gallery__block">
                    <div className="block__description">
                      <h2 className="block__header">
                        Декоративний камінь
                        <br />
                        для ландшафтного дизайну
                      </h2>
                    </div>
                    <img className="block__image" src={pic6} alt="" />
                  </div>
                </Link>
                <Link to="/production/rubbleStone">
                  <div className="gallery__block">
                    <div className="block__description">
                      <h2 className="block__header">
                        Бут гранітний
                        <br />
                        обрізний
                      </h2>
                    </div>
                    <img className="block__image" src={pic7} alt="" />
                  </div>
                </Link>
                {/* <Link to="/production/7"> */}
                <div className="gallery__block">
                  <div className="block__description">
                    <h2 className="block__header">
                      Вироби
                      <br />з дерева
                    </h2>
                  </div>
                  <img className="block__image" src={pic8} alt="" />
                </div>
                {/* </Link> */}
                {/* <Link to="/production/8"> */}
                <div className="gallery__block">
                  <div className="block__description">
                    <h2 className="block__header">
                      Саджанці
                      <br />
                      дерев
                    </h2>
                  </div>
                  <img className="block__image" src={pic9} alt="" />
                </div>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </section>

        <div className="page__banner-block">
          <div className="banner-block__image4">
            <h1 className="banner-header">
              Пам'ятники
              <br />з граніту
            </h1>
            <p className="banner-text4">
              Виготовлення та встановлення. Різні види та моделі. Виконуємо
              роботи під ключ. Як із нашою установкою, так і самовивіз.
            </p>
          </div>
        </div>
      </main>
    </Layout>
  );
}
