import React, { Component } from "react";
import Layout from "layouts/Layout";
import "assets/styles/services.scss";

export default class ServicesPage extends Component {
  render() {
    return (
      <Layout>
        <main className="additional-info">
          <div className="page__banner-block">
            <div className="banner-block__image3">
              <h1 className="banner-header">
                Оброблення
                <br />
                граніту
              </h1>
              <p className="banner-text6">
                Наші спеціалісти проведуть необхідну обробку каменю та виконають
                підготовку виробу під вимоги замовника. Обробка каменю
                здійснюється на верстатах або нашими майстрами вручну.
              </p>
            </div>
          </div>
          <div className="info container">
            <div className="info__body">
              <div className="info__block">
                <div className="description__block">
                  <div className="header">
                    <h1>Пам'ятник та надгробки</h1>
                  </div>
                  <div className="text">
                    <p>
                      Ви можете у нас замовити та купити пам'ятник або
                      надгробок. Ми подбаємо, щоб результат повністю відповідав
                      вашим очікуванням. Із чого складається вартість
                      пам'ятника? Камінь – сорт граніту, висота пам'ятника та
                      форма, тип полірування. Гравірування - ПІБ, дати життя,
                      портрет, свічки, квіти та епітафії. Оточення - ваза,
                      лавочка та столик, плита, цоколь Послуги - доставка до
                      місця, встановлення пам'ятника Благоустрій - укладання
                      плитки, вимощення, поребрик та ін.
                    </p>
                  </div>
                </div>
              </div>
              <div className="info__block">
                <div className="description__block">
                  <div className="header">
                    <h1>Плитка гранітна облицювальна</h1>
                  </div>
                  <div className="text">
                    <p>
                      Незважаючи на безліч пропозицій, клієнт не завжди може
                      знайти хорошого постачальника, готового запропонувати
                      вигідні ціни. З нами ви отримуєте переваги: Придбати
                      гранітну плитку можете у будь-якій кількості. Здійснюємо
                      прямі постачання, тож клієнт не платить за послуги
                      посередників.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    );
  }
}
