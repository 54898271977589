import sTilesPic1 from "assets/images/gallery/gallery_sidewalkTiles/pic1.jpg";
import sTilesPic2 from "assets/images/gallery/gallery_sidewalkTiles/pic2.jpg";
import sTilesPic3 from "assets/images/gallery/gallery_sidewalkTiles/pic3.jpg";
import sTilesPic4 from "assets/images/gallery/gallery_sidewalkTiles/pic4.jpg";
import sTilesPic5 from "assets/images/gallery/gallery_sidewalkTiles/pic5.jpg";
import sTilesPic6 from "assets/images/gallery/gallery_sidewalkTiles/pic6.jpg";
import sTilesPic7 from "assets/images/gallery/gallery_sidewalkTiles/pic7.jpg";
import sTilesPic8 from "assets/images/gallery/gallery_sidewalkTiles/pic8.jpg";
import sTilesPic10 from "assets/images/gallery/gallery_sidewalkTiles/pic10.jpg";
import sTilesPic11 from "assets/images/gallery/gallery_sidewalkTiles/pic11.jpg";
import sTilesPic12 from "assets/images/gallery/gallery_sidewalkTiles/pic12.jpg";
import sTilesPic13 from "assets/images/gallery/gallery_sidewalkTiles/pic13.jpg";
import sTilesPic14 from "assets/images/gallery/gallery_sidewalkTiles/pic14.jpg";
import sTilesPic15 from "assets/images/gallery/gallery_sidewalkTiles/pic15.jpg";
import sTilesPic16 from "assets/images/gallery/gallery_sidewalkTiles/pic16.jpg";
import sTilesPic17 from "assets/images/gallery/gallery_sidewalkTiles/pic17.jpg";
import sTilesPic18 from "assets/images/gallery/gallery_sidewalkTiles/pic18.jpg";
import sTilesPic19 from "assets/images/gallery/gallery_sidewalkTiles/pic19.jpg";

import fTilesPic1 from "assets/images/gallery/gallery_facingTiles/pic1.jpg";
import fTilesPic2 from "assets/images/gallery/gallery_facingTiles/pic2.jpg";
import fTilesPic3 from "assets/images/gallery/gallery_facingTiles/pic3.jpg";
import fTilesPic4 from "assets/images/gallery/gallery_facingTiles/pic4.jpg";
import fTilesPic5 from "assets/images/gallery/gallery_facingTiles/pic5.jpg";
import fTilesPic6 from "assets/images/gallery/gallery_facingTiles/pic6.jpg";
import fTilesPic7 from "assets/images/gallery/gallery_facingTiles/pic7.jpg";
import fTilesPic8 from "assets/images/gallery/gallery_facingTiles/pic8.jpg";
import fTilesPic9 from "assets/images/gallery/gallery_facingTiles/pic9.jpg";
import fTilesPic10 from "assets/images/gallery/gallery_facingTiles/pic10.jpg";
import fTilesPic11 from "assets/images/gallery/gallery_facingTiles/pic11.jpg";
import fTilesPic12 from "assets/images/gallery/gallery_facingTiles/pic12.jpg";
import fTilesPic13 from "assets/images/gallery/gallery_facingTiles/pic13.jpg";
import fTilesPic14 from "assets/images/gallery/gallery_facingTiles/pic14.jpg";
import fTilesPic15 from "assets/images/gallery/gallery_facingTiles/pic15.jpg";
import fTilesPic16 from "assets/images/gallery/gallery_facingTiles/pic16.jpg";
import fTilesPic17 from "assets/images/gallery/gallery_facingTiles/pic17.jpg";
import fTilesPic18 from "assets/images/gallery/gallery_facingTiles/pic18.jpg";
import fTilesPic19 from "assets/images/gallery/gallery_facingTiles/pic19.jpg";
import fTilesPic20 from "assets/images/gallery/gallery_facingTiles/pic20.jpg";
import fTilesPic21 from "assets/images/gallery/gallery_facingTiles/pic21.jpg";
import fTilesPic22 from "assets/images/gallery/gallery_facingTiles/pic22.jpg";
import fTilesPic23 from "assets/images/gallery/gallery_facingTiles/pic23.jpg";
import fTilesPic24 from "assets/images/gallery/gallery_facingTiles/pic24.jpg";
import fTilesPic25 from "assets/images/gallery/gallery_facingTiles/pic25.jpg";
import fTilesPic26 from "assets/images/gallery/gallery_facingTiles/pic26.jpg";
import fTilesPic27 from "assets/images/gallery/gallery_facingTiles/pic27.jpg";
import fTilesPic28 from "assets/images/gallery/gallery_facingTiles/pic28.jpg";
import fTilesPic29 from "assets/images/gallery/gallery_facingTiles/pic29.jpg";
import fTilesPic30 from "assets/images/gallery/gallery_facingTiles/pic30.jpg";

import monumPic1 from "assets/images/gallery/gallery_monuments/pic1.jpg";
import monumPic2 from "assets/images/gallery/gallery_monuments/pic2.jpg";
import monumPic3 from "assets/images/gallery/gallery_monuments/pic3.jpg";
import monumPic4 from "assets/images/gallery/gallery_monuments/pic4.jpg";
import monumPic5 from "assets/images/gallery/gallery_monuments/pic5.jpg";
import monumPic6 from "assets/images/gallery/gallery_monuments/pic6.jpg";
import monumPic7 from "assets/images/gallery/gallery_monuments/pic7.jpg";
import monumPic8 from "assets/images/gallery/gallery_monuments/pic8.jpg";
import monumPic10 from "assets/images/gallery/gallery_monuments/pic10.jpg";
import monumPic11 from "assets/images/gallery/gallery_monuments/pic11.jpg";
import monumPic12 from "assets/images/gallery/gallery_monuments/pic12.jpg";
import monumPic13 from "assets/images/gallery/gallery_monuments/pic13.jpg";
import monumPic14 from "assets/images/gallery/gallery_monuments/pic14.jpg";
import monumPic15 from "assets/images/gallery/gallery_monuments/pic15.jpg";
import monumPic16 from "assets/images/gallery/gallery_monuments/pic16.jpg";
import monumPic17 from "assets/images/gallery/gallery_monuments/pic17.jpg";
import monumPic18 from "assets/images/gallery/gallery_monuments/pic18.jpg";
import monumPic19 from "assets/images/gallery/gallery_monuments/pic19.jpg";
import monumPic20 from "assets/images/gallery/gallery_monuments/pic20.jpg";

import furnPic1 from "assets/images/gallery/gallery_furniture/pic1.jpg";
import furnPic2 from "assets/images/gallery/gallery_furniture/pic2.jpg";
import furnPic3 from "assets/images/gallery/gallery_furniture/pic3.jpg";
import furnPic4 from "assets/images/gallery/gallery_furniture/pic4.jpg";
import furnPic5 from "assets/images/gallery/gallery_furniture/pic5.jpg";
import furnPic6 from "assets/images/gallery/gallery_furniture/pic6.jpg";
import furnPic7 from "assets/images/gallery/gallery_furniture/pic7.jpg";
import furnPic8 from "assets/images/gallery/gallery_furniture/pic8.jpg";
import furnPic9 from "assets/images/gallery/gallery_furniture/pic9.jpg";
import furnPic10 from "assets/images/gallery/gallery_furniture/pic10.jpg";
import furnPic11 from "assets/images/gallery/gallery_furniture/pic11.jpg";
import furnPic12 from "assets/images/gallery/gallery_furniture/pic12.jpg";
import furnPic13 from "assets/images/gallery/gallery_furniture/pic13.jpg";
import furnPic14 from "assets/images/gallery/gallery_furniture/pic14.jpg";
import furnPic15 from "assets/images/gallery/gallery_furniture/pic15.jpg";
import furnPic16 from "assets/images/gallery/gallery_furniture/pic16.jpg";
import furnPic17 from "assets/images/gallery/gallery_furniture/pic17.jpg";
import furnPic18 from "assets/images/gallery/gallery_furniture/pic18.jpg";

import pavPic1 from "assets/images/gallery/gallery_pavingStones/pic1.jpg";
import pavPic2 from "assets/images/gallery/gallery_pavingStones/pic2.jpg";
import pavPic3 from "assets/images/gallery/gallery_pavingStones/pic3.jpg";
import pavPic4 from "assets/images/gallery/gallery_pavingStones/pic4.jpg";
import pavPic5 from "assets/images/gallery/gallery_pavingStones/pic5.jpg";
import pavPic6 from "assets/images/gallery/gallery_pavingStones/pic6.jpg";
import pavPic7 from "assets/images/gallery/gallery_pavingStones/pic7.jpg";
import pavPic8 from "assets/images/gallery/gallery_pavingStones/pic8.jpg";
import pavPic9 from "assets/images/gallery/gallery_pavingStones/pic9.jpg";
import pavPic10 from "assets/images/gallery/gallery_pavingStones/pic10.jpg";

import desrubPic1 from "assets/images/gallery/gallery_designedRubbleStone/pic1.jpg";
import desrubPic2 from "assets/images/gallery/gallery_designedRubbleStone/pic2.jpg";
import desrubPic3 from "assets/images/gallery/gallery_designedRubbleStone/pic3.jpg";
import desrubPic4 from "assets/images/gallery/gallery_designedRubbleStone/pic4.jpg";
import desrubPic5 from "assets/images/gallery/gallery_designedRubbleStone/pic5.jpg";
import desrubPic6 from "assets/images/gallery/gallery_designedRubbleStone/pic6.jpg";
import desrubPic7 from "assets/images/gallery/gallery_designedRubbleStone/pic7.jpg";
import desrubPic8 from "assets/images/gallery/gallery_designedRubbleStone/pic8.jpg";
import desrubPic9 from "assets/images/gallery/gallery_designedRubbleStone/pic9.jpg";
import desrubPic10 from "assets/images/gallery/gallery_designedRubbleStone/pic10.jpg";

import rubPic1 from "assets/images/gallery/gallery_rubbleStone/pic1.jpg";
import rubPic2 from "assets/images/gallery/gallery_rubbleStone/pic2.jpg";
import rubPic3 from "assets/images/gallery/gallery_rubbleStone/pic3.jpg";
import rubPic4 from "assets/images/gallery/gallery_rubbleStone/pic4.jpg";
import rubPic5 from "assets/images/gallery/gallery_rubbleStone/pic5.jpg";
import rubPic6 from "assets/images/gallery/gallery_rubbleStone/pic6.jpg";
import rubPic7 from "assets/images/gallery/gallery_rubbleStone/pic7.jpg";
import rubPic8 from "assets/images/gallery/gallery_rubbleStone/pic8.jpg";
import rubPic9 from "assets/images/gallery/gallery_rubbleStone/pic9.jpg";

interface IProduct {
  title: string;
  description: string;
  pictures: string[];
}

interface IProducts {
  [key: string]: IProduct;
}

const products: IProducts = {
  sidewalkTiles: {
    title: "Плитка гранітна для тротуарів",
    description:
      "У нас можна замовити та купити гранітну плитку для тротуарів. Така плитка стійка до зовнішнього впливу та добре захищає від нього основу, на якій вона змонтована. Матеріал має не тільки респектабельний естетичний вигляд, а як і будь-яка класика не виходить з моди. Таке покриття завжди буде доречним. Гранітне облицювання не потребує захисних покриттів. Легко очищається та досить стійко до забруднень. Термін служби гранітної плитки може становити десятиліття або століття",
    pictures: [
      sTilesPic1,
      sTilesPic2,
      sTilesPic3,
      sTilesPic4,
      sTilesPic5,
      sTilesPic6,
      sTilesPic7,
      sTilesPic8,
      sTilesPic10,
      sTilesPic11,
      sTilesPic12,
      sTilesPic14,
      sTilesPic15,
      sTilesPic16,
      sTilesPic17,
      sTilesPic18,
      sTilesPic19,
    ],
  },

  facingTiles: {
    title: "Гранітна облицювальна плитка ",
    description:
      "Гранітна плитка є природним матеріалом без штучних добавок.  Такий матеріал чудово виглядатиме як у екстер'єрі так і у вашому інтер'єрі.  Плитка з граніту буває різних кольорів та форм, що при вдалих комбінаціях створює цікаві геометричні композиції на фасадах будівель, пожвавлюючи одноманітне міське середовище. У нас ви можете замовити та купити гранітну плитку для будь-яких своїх потреб.",
    pictures: [
      fTilesPic1,
      fTilesPic2,
      fTilesPic3,
      fTilesPic4,
      fTilesPic5,
      fTilesPic6,
      fTilesPic7,
      fTilesPic8,
      fTilesPic9,
      fTilesPic10,
      fTilesPic11,
      fTilesPic12,
      fTilesPic13,
      fTilesPic14,
      fTilesPic15,
      fTilesPic16,
      fTilesPic17,
      fTilesPic18,
      fTilesPic19,
      fTilesPic20,
      fTilesPic21,
      fTilesPic22,
      fTilesPic23,
      fTilesPic24,
      fTilesPic25,
      fTilesPic26,
      fTilesPic27,
      fTilesPic28,
      fTilesPic29,
      fTilesPic30,
    ],
  },

  monuments: {
    title: "Пам'ятники та надгробки",
    description:
      "Граніт – найкращий матеріал для виготовлення ритуальних виробів. Його міцність забезпечує величезний термін експлуатації кам'яних елементів. Найбільш традиційним та економічним рішенням для оформлення місця поховання вважається встановлення пам'ятника на підставці та квітника над могилою. Портрет, ім'я, дати та епітафія розміщуються на вертикальному пам'ятнику, а квітник прикрашається травою чи рослинами. У нас можете замовити стеллу, квітник із граніту, надгробну плиту або звичайний надгробній пам'ятник.",
    pictures: [
      monumPic1,
      monumPic2,
      monumPic3,
      monumPic4,
      monumPic5,
      monumPic6,
      monumPic7,
      monumPic10,
      monumPic11,
      monumPic12,
      monumPic16,
      monumPic17,
      monumPic18,
      monumPic19,
      monumPic20,
    ],
  },

  furniture: {
    title: "Вироби з граніту",
    description:
      "Зараз у моду все більше і більше входять натуральні матеріали для будівництва та оздоблення, благоустрою та виготовлення меблів. Багато хто замислюється не тільки про красу матеріалів, що вибираються, але і про їх довговічність. Граніт є найоптимальнішою природною сировиною для практично будь-яких цілей. Застосовуючи граніт як матеріал для інтер'єру ви отримуєте не тільки красивий, але практично вічний елемент декору, який радуватиме вас і ваших близьких не одне десятиліття.",
    pictures: [
      furnPic1,
      furnPic2,
      furnPic3,
      furnPic4,
      furnPic5,
      furnPic6,
      furnPic7,
      furnPic8,
      furnPic9,
      furnPic10,
      furnPic11,
      furnPic12,
      furnPic13,
      furnPic14,
      furnPic15,
      furnPic16,
      furnPic17,
      furnPic18,
    ],
  },

  pavingStones: {
    title: "Бруківка пиляна колота для тротуарів",
    description:
      "Гранітна бруківка має низку незаперечних переваг, основними з яких є відмінні естетичні властивості, міцність і довговічність, і така важлива якість як екологічність. При цьому, гранітна бруківка практично не має недоліків. Єдиним мінусом може бути названа велика маса будівельного елемента через високу щільність вихідного матеріалу. І то цей показник не такий критичний. Купити гранітну бруківку у нас ви можете заздалегідь зв'язавшись з нами поштою або зателефонувавши.",
    pictures: [
      pavPic1,
      pavPic2,
      pavPic3,
      pavPic4,
      pavPic5,
      pavPic6,
      pavPic7,
      pavPic8,
      pavPic9,
      pavPic10,
    ],
  },

  designedRubbleStone: {
    title: "Декоративний камінь для ландшафного дизайну",
    description:
      "Декоративний камінь це фрагменти скельної породи різних видів та розмірів. Він не боїться морозу, вогню, високої температури, його можна мити та чистити. За ці властивості камінь знайшов своє застосування як декор ландшафтного дизайну.",
    pictures: [
      desrubPic1,
      desrubPic2,
      desrubPic3,
      desrubPic4,
      desrubPic5,
      desrubPic6,
      desrubPic7,
      desrubPic8,
      desrubPic9,
      desrubPic10,
    ],
  },
  rubbleStone: {
    title: "Бут гранітний обрізний",
    description:
      "Гранітний бут обрізний (некондиційні відходи, обрізки граніту та гранітний бій) широко використовують у будівельних та декоративних цілях. Обрізний бут зберігає всі властивості натурального каменю. У шматках граніту після його обробки фрезерним верстатом залишаються міцність, водостійкість та морозостійкість.  Купить обрезной бут можно у нас. Цені на накой материал на порядок ниже.",
    pictures: [
      rubPic1,
      rubPic2,
      rubPic3,
      rubPic4,
      rubPic5,
      rubPic6,
      rubPic7,
      rubPic8,
      rubPic9,
    ],
  },
};

export default products;
