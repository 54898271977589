import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./assets/styles/reset.css";
import "./assets/styles/style.scss";
import "@splidejs/react-splide/css";
import ContactsPage from "pages/ContactsPage";
import MainPage from "pages/MainPage";
import NotFoundPage from "pages/NotFoundPage";
import ProductionPage from "pages/ProductionPage";
import GalleryComponent from "components/GalleryComponent";
import ServicesPage from "pages/ServicesPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/production",
    element: <ProductionPage />,
  },
  {
    path: "/production/:productName",
    element: <GalleryComponent />,
  },
  {
    path: "/contacts",
    element: <ContactsPage />,
  },
  {
    path: "/services",
    element: <ServicesPage />,
  },
  {
    path: "/*",
    element: <MainPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
