import { useState } from "react";
import "assets/styles/navbar.css";
import Logo from "assets/images/random/logo.svg";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  function closeNav() {
    setIsNavExpanded(false);
  }

  return (
    <header>
      <nav className="navigation container">
        <Link to="/" className="brand-name">
          <img src={Logo} alt="" />
        </Link>
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <ul>
            <li className="navl">
              <Link to="/" onClick={closeNav}>
                Головна
              </Link>
            </li>
            <li className="navl">
              <Link to="/production" onClick={closeNav}>
                Виробництво
              </Link>
            </li>
            <li className="navl">
              <Link to="/services" onClick={closeNav}>
                Послуги
              </Link>
            </li>
            <li className="navl">
              <Link to="/contacts" onClick={closeNav}>
                Контакти
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
