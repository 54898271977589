import Layout from "layouts/Layout";
import React, { Component } from "react";
import "assets/styles/main.scss";

import pic1 from "assets/images/random/pic1.png";
import pic2 from "assets/images/random/pic2.png";
import pic3 from "assets/images/random/pic3.jpg";
import pic4 from "assets/images/random/pic4.png";
import icon1 from "assets/images/random/icon1.png";
import icon2 from "assets/images/random/icon2.png";
import { Link } from "react-router-dom";

export default class MainPage extends Component {
  render() {
    return (
      <Layout>
        <main className="page">
          <div className="page__banner-block">
            <div className="banner-block__image1">
              <h1 className="banner-header">
                Гранітна плитка
                <br />
                для підлоги
              </h1>
              <p className="banner-text1">
                Гранітна підлога відрізняється особливою зносостійкістю, тому
                цей варіант найкращий для комерційних та громадських приміщень.
              </p>
            </div>
          </div>

          <div className="services__container container">
            <div className="services__body">
              <div className="services__column">
                <div className="services__item item-service">
                  <h3 className="item-service__title-border">
                    Облицювальна гранітна плитка
                  </h3>
                  <div className="item-service__text text1">
                    Гранітна облицювальна плитка є чудовим матеріалом для
                    зовнішньої обробки цоколів, фасадів, підлог, сходів як зовні
                    так і всередині приміщення. Добре виготовлені плитки із
                    граніту будуть служити не один рік, не змінюючи свій чудовий
                    зовнішній вигляд. Граніт досить часто застосовують в
                    архітектурі як облицювальний матеріал завдяки його
                    особливому полімінеральному складу, який і дає таку міцність
                    каменю, а також створює його особливу декоративність.
                    Гранітна плитка є природним матеріалом без штучних добавок.
                  </div>
                </div>
              </div>
              <div className="services__column">
                <div className="services__item item-service">
                  <div className="item-service__icon">
                    <img src={icon2} alt="" />
                  </div>
                  <h3 className="item-service__title">
                    Гранітна плитка для тротуару
                  </h3>
                  <div className="item-service__text text2">
                    Граніт – унікальний матеріал. І однією з унікальних
                    властивостей цього натурального каменю є можливість
                    використання його в будь-яких кліматичних зонах та місцях зі
                    значними механічними навантаженнями. Це робить граніт
                    незамінним матеріалом для вулиці, а точніше для мощення
                    доріжок, тротуарів, площ і т.д.
                  </div>
                </div>
              </div>
              <div className="services__column">
                <div className="services__item item-service">
                  <div className="item-service__icon">
                    <img src={icon1} alt="" />
                  </div>
                  <h3 className="item-service__title">
                    Встановлення типових пам'ятників та меморіалів
                  </h3>
                  <div className="item-service__text text3">
                    Виготовлення пам'ятників та меморіальних комплексів з різних
                    кольорів та поєднань граніту «під ключ». Гравіювання,
                    благоустрій, укладання плитки, вимощення та ін.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page__banner-block">
            <div className="banner-block__image2">
              <h1 className="banner-header">
                Вироби
                <br />з граніту
              </h1>
              <p className="banner-text2">
                Завдяки широкій гамі текстур, кольорів та відтінків граніті
                підходить до всіх стилів в оформленні інтер'єрів та екстер'єрів.
              </p>
            </div>
          </div>
          <section className="page__product-blocks">
            <div className="products__container container">
              <div className="products__row">
                <Link to="/production/furniture">
                  <div className="products__block">
                    <img className="item__image" src={pic1} alt="" />
                    <div className="item__description">
                      <h2 className="item__header">
                        Балясини
                        <br />з граніту
                      </h2>
                      <div className="item__text">
                        Поруччя та балясини з граніту не піддаються високим
                        навантаженням, тому гранітну балюстраду можна назвати
                        вічною конструкцією. Гранітна балюстрада не втрачає
                        свого первозданного вигляду довгі століття, лише зрідка
                        потребуючи зміцнення.
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/production/monuments">
                  <div className="products__block">
                    <img className="item__image" src={pic3} alt="" />
                    <div className="item__description">
                      <h2 className="item__header">Пам`ятники та надгробки</h2>
                      <div className="item__text">
                        Пам'ятники з граніту – найпоширеніший вид надгробків з
                        натурального каменю. Підсумкова вартість пам'ятника з
                        граніту складається з ціни: матеріал, виготовлення та
                        оформлення. До ціноутворення включаються також доставка,
                        установка. Можлива скида.
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="products__row">
                <Link to="/production/designedRubbleStone">
                  <div className="products__block">
                    <img className="item__image" src={pic4} alt="" />
                    <div className="item__description">
                      <h2 className="item__header">
                        Декоративний
                        <br />
                        камінь
                      </h2>
                      <div className="item__text">
                        Декоративний камінь можна використовувати в ландшафті
                        садів, парків, для прикраси територій поблизу водойм,
                        для садових будівель - альтанок, парканів, декоративних
                        стовпів та багато іншого. Камінь має необроблену, колоту
                        поверхню.
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/production/pavingStones">
                  <div className="products__block">
                    <img className="item__image" src={pic2} alt="" />
                    <div className="item__description">
                      <h2 className="item__header">Бруківка для тротуарів</h2>
                      <div className="item__text">
                        Бруківка з натурального каменю є зразковим матеріалом
                        для вуличного мощення. Це вічне дорожнє покриття, яке
                        має масу вагомих експлуатаційних переваг. Покриття
                        виходить настільки міцним, що спокійно навіть витримує
                        важкий вантажний транспорт.
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    );
  }
}
