import React, { Component } from "react";
import Layout from "../layouts/Layout";
import "assets/styles/contacts.scss";

import Address from "assets/images/footer/Adress.svg";
import Gmail from "assets/images/footer/Gmail.svg";
import Phone from "assets/images/footer/Phone.svg";

export default class ContactsComponent extends Component {
  render() {
    return (
      <Layout showFooter={false}>
        <div className="page__banner-block">
          <div className="banner-block__image6">
            <h1 className="banner-header">
              Гранітна плитка
              <br />
              для підлоги
            </h1>
            <p className="banner-text6">
              Гранітна підлога відрізняється особливою зносостійкістю, тому цей
              варіант найкращий для комерційних та громадських приміщень.
            </p>
          </div>
        </div>
        <main className="contacts-page container">
          <div className="contacts-header">
            <h1>Як нас знайти</h1>
          </div>

          <div className="contacts-main">
            <div className="contacts">
              <div className="contact">
                <img src={Phone} alt="" />
                <div className="contact__description">
                  <h3>Телефон</h3>
                  <a href="tel:097-602-3017">097-602-3017</a>
                  <br />
                  <a href="tel:095-129-1641">095-129-1641</a>
                </div>
              </div>
              <div className="contact">
                <img src={Gmail} alt="" />
                <div className="contact__description">
                  <h3>Пошта</h3>
                  <a href="mailto: al3ksgran1t@gmail.com">
                    al3ksgran1t@gmail.com
                  </a>
                </div>
              </div>
              <div className="contact">
                <img src={Address} alt="" />
                <div className="contact__description">
                  <h3>Адреса</h3>
                  <a href="https://goo.gl/maps/AiaQSLgoR2gQCFNP9 ">
                    м. Олександрія
                  </a>
                </div>
              </div>
            </div>

            <div className="gmap">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42161.05615600617!2d33.039827336082!3d48.665980304068405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d09d92b326f807%3A0xe117d4fe41dc0d9!2z0JDQu9C10LrRgdCw0L3QtNGA0LjRjywg0JrQuNGA0L7QstC-0LPRgNCw0LTRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwgMjgwMDE!5e0!3m2!1sru!2sua!4v1664384459524!5m2!1sru!2sua"></iframe>
            </div>
          </div>
        </main>
      </Layout>
    );
  }
}
