import React, { Component } from "react";
import Address from "assets/images/footer/Adress.svg";
import Gmail from "assets/images/footer/Gmail.svg";
import Phone from "assets/images/footer/Phone.svg";
import "assets/styles/footer.scss";

export default class FooterComponent extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer__contacts container">
          <div className="contacts-main">
            <div className="contacts">
              <div className="contact">
                <img src={Phone} alt="" />
                <div className="contact__description">
                  <h3>Телефон</h3>
                  <a href="tel:097-602-3017">097-602-3017</a>
                  <br />
                  <a href="tel:095-129-1641">095-129-1641</a>
                </div>
              </div>
              <div className="contact">
                <img src={Gmail} alt="" />
                <div className="contact__description">
                  <h3>Пошта</h3>
                  <a href="mailto: al3ksgran1t@gmail.com">
                    al3ksgran1t@gmail.com
                  </a>
                </div>
              </div>
              <div className="contact">
                <img src={Address} alt="" />
                <div className="contact__description">
                  <h3>Адреса</h3>
                  <a href="https://goo.gl/maps/AiaQSLgoR2gQCFNP9 ">
                    м. Олександрія
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-text">© 2022 Aleksgranit</div>
      </footer>
    );
  }
}
