import FooterComponent from "components/FooterComponent";
import Navbar from "components/NavbarComponent";
import { useEffect } from "react";

type LayoutProps = {
  children: React.ReactNode;
  showFooter?: boolean;
  showHeader?: boolean;
};

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  showHeader = true,
  showFooter = true,
}) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      {showHeader && <Navbar />}
      {children}
      {showFooter && <FooterComponent />}
    </>
  );
};

export default Layout;
