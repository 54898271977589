import React, { Component } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "assets/styles/gallery.scss";
import { Grid } from "@splidejs/splide-extension-grid";
import { useParams } from "react-router-dom";
import products from "data/products";
import Layout from "layouts/Layout";

export default function GalleryComponent() {
  const { productName } = useParams();
  const product = products[productName || " "];
  return (
    <Layout>
      <div className="page__banner-block">
        <div className="banner-block__image5">
          <h1 className="banner-header">
            Вироби з граніту
            <br />
          </h1>
          <p className="banner-text5">
            Якісні вироби з граніту надійно служать десятки років і не виходять
            із моди з часом.
          </p>
        </div>
      </div>
      <main className="gallery-page">
        <div className="page__category-description _container">
          <div className="description__body">
            <div className="description__text">
              <h2 className="title">{product.title}</h2>
              <p className="description">{product.description}</p>
            </div>
          </div>
          <Splide
            hasTrack={false}
            aria-label="My Favorite Images"
            options={{
              cover: true,
              heightRatio: 0.9,
              gap: 10,
              perPage: 1,
              rewind: true,
              rewindByDrag: true,
              autoplay: true,

              grid: { rows: 3, cols: 3, gap: { row: 10, col: 10 } },
              breakpoints: {
                600: {
                  arrows: false,
                  pagination: true,
                  heightRatio: 1.2,
                  grid: { rows: 3, cols: 1, gap: { row: 20, col: 30 } },
                  cover: true,
                },
                460: {
                  arrows: false,
                  pagination: true,
                  heightRatio: 1,
                  grid: { rows: 3, cols: 1, gap: { row: 10, col: 0 } },
                  cover: true,
                },
              },
            }}
            extensions={{ Grid }}
          >
            <div className="custom-wrapper">
              <SplideTrack className="splideTrack">
                {product.pictures.map((pic, index) => (
                  <SplideSlide key={index}>
                    <img src={pic} alt="" />
                  </SplideSlide>
                ))}
              </SplideTrack>
            </div>
            <ul className="splide__pagination"></ul>
          </Splide>
        </div>
      </main>
    </Layout>
  );
}
